<template>
  <a-carousel :autoplay="false" arrows dotsClass="dots-click" class="banner-swiper">
    <!-- 箭头 -->
    <div
      slot="prevArrow"
      class="custom-slick-arrow arrow-prev"
      style="left: 10px;zIndex: 1"
    />
    <div slot="nextArrow"
      class="custom-slick-arrow arrow-next"
      style="right: 10px"
    />
    <!-- 箭头end -->
    <div class="swiper-slide">
      <div class="swiper-slide-item">
        <!--<img src="http://www.lndfwljt.com/image/2021/07/09/banner1.jpg"/>-->
        <img :src="indexInfo[0].imageUrl"/>
        <div class="bannerTxt txt1">
          <b><p class="f1">大方未来集团</p></b>
          <p class="f2">集团致力于医药健康产业领域</p>
          <p class="f3">集合医药批发物流、医药零售连锁、医药生物科技、医药电子商务、医药生产研发、医药健康孵化六大业务板块</p>
          <a class="bnnr-btn" href="" @click="jump($event, '/about/1')">了解更多</a>
        </div>
      </div>
    </div>
    <div class="swiper-slide">
      <div class="swiper-slide-item">
      <!--<img src="http://www.lndfwljt.com/image/2021/07/09/banner2.jpg"/>-->
        <img :src="indexInfo[1].imageUrl"/>
        <div class="bannerTxt txt2">
          <b><p class="f1">战略方针</p></b>
          <p class="f2">一体、两翼、三化</p>
          <p class="f3">坚持以医药健康产业为主体，零售连锁与医药物流为两翼，围绕网络化、平台化、资本化的“一体、两翼、三化”战略方针</p>
          <a class="bnnr-btn" href="" @click="jump($event, '/about/6')">了解更多</a>
        </div>
      </div>
    </div>
    <div class="swiper-slide">
      <div class="swiper-slide-item">
      <!--<img src="http://www.lndfwljt.com/image/2021/07/09/banner3.jpg"/>-->
      <img :src="indexInfo[2].imageUrl"/>
      <div class="bannerTxt txt3">
        <b><p class="f1">愿景与使命</p></b>
        <p class="f2">高瞻远瞩 · 制胜未来</p>
        <p class="f3">携手追逐大方梦想，合作铸就未来辉煌！</p>
        <p class="f3">以识为主，以才为辅。坚定信念，果敢向前！</p>
        <a class="bnnr-btn" href="" @click="jump($event, '/about/3')" >了解更多</a>
      </div>
      </div>
    </div>
  </a-carousel>
</template>
<script>
import { getIndexInfo } from '@/api/home.js'
export default {
  data () {
    return {
      indexInfo: []
    }
  },
  async mounted () {
    const data = await getIndexInfo({})
    if (data.code === 200) {
      this.indexInfo = data.data.pictures
    }
  },
  methods: {
    jump ($event, path) {
      $event.preventDefault()
      this.$router.push({ path: path })
    }
  }
}
</script>
<style lang="less" scoped>
  .ant-carousel .custom-slick-arrow {
    width: 36px;
    height: 62px;
    color: #fff;
    margin-top: -30px;
    &::before {
      display: none;
    }
    &.arrow-prev {
      background-image: url("http://www.lndfwljt.com/image/2021/07/09/ic-arrow-l.png");
      left: 0.2rem;
      right: auto;
    }
    &.arrow-next {
      background-image: url("http://www.lndfwljt.com/image/2021/07/09/ic-arrow-r.png");
      right: 0.2rem;
      left: auto;
    }
  }
  .swiper-slide {
    height: auto;
    &-item {
      position: relative;
    }
    img {
      width: 100%;
      height: auto;
    }
    .bannerTxt {
      max-width: 90%;
      margin: 0 auto;
      color: #FFF;
      position: absolute;
      left: 11%;
      bottom: 12%;
      text-align: left;
      line-height: 1.5;
      .f1 {
        font-size: 0.72rem;
        padding-bottom: 0.16rem;
        letter-spacing: 0.05rem;
      }
      .f2 {
        font-size: 0.36rem;
        padding-bottom: 0.2rem;
        letter-spacing: 0.05rem;
      }
      .f3 {
        font-size: 0.2rem;
        padding-bottom: 0.14rem;
        letter-spacing: 0.03rem;
      }
      .bnnr-btn {
        padding: 0 0.2rem;
        border: 2px solid #fff;
        color: #fff;
        letter-spacing: 0.05px;
        font-size: 0.21rem;
        line-height: 2;
        border-radius: 0.06rem;
        display: inline-block;
      }
    }
    .bannerTxtBg {
      width: 100%;
      position: absolute;
      left: 11%;
      bottom: 12%;
      .bannerTxt {
        position: static;
      }
    }
    .bt-bg {
      width: 100%;
      background-color: rgba(0,0,0,.3);
      padding: 0.3rem 0;
      dl {
        width: 12rem;
        margin: auto;
        font-size: 0.22rem;
        color: #fff;
        position: relative;
        zoom: 1;
        dt {
          width: 3rem;
          float: left;
          img {
            width: 2.9rem;
          }
        }
        dd {
          line-height: 2;
        }
      }
      .bnnr-btn {
        position: absolute;
        right: 0.1rem;
        bottom: 0.1rem;
        padding: 0 0.2rem;
        border: 2px solid #fff;
        color: #fff;
        letter-spacing: 5px;
        font-size: 0.21rem;
        line-height: 2;
        border-radius: 6px;
        display: inline-block;
      }
    }
  }
</style>
<style lang="less">
  .banner-swiper.ant-carousel  .dots-click li {
    button {
      width: 0.14rem;
      height: 0.14rem;
      border-radius: 50%;
    }
  }
  .banner-swiper.ant-carousel  .dots-click li.slick-active {
    button {
      width: 0.4rem;
      height: 0.14rem;
      background-color: #6cbbe9;
      border: 1px solid #fff;
      border-radius: 7px;
    }
  }
  .banner-swiper.ant-carousel .slick-dots-bottom {
    bottom: 0.4rem;
  }
</style>
