import { Get } from '@/utils/fetch'

export const fetchOtherNewLists = ({ pageNum = 1, pageSize = 10, seatTop, seat }) => {
  return Get('/findPage', {
    pageNum, pageSize, seatTop, seat
  })
}

export const fetchMainNewLists = ({ type, pageNum, pageSize = 5, isTop }) => {
  return Get('/findPage', {
    type, pageNum, pageSize, isTop
  })
}

export const fetchCompanyNewLists = ({ type, pageNum, pageSize = 3, isTop = 0 }) => {
  return Get('/findPage', {
    type, pageNum, pageSize, isTop
  })
}

// 获取新闻详情
export const fetchNewDetail = ({ type, id }) => {
  return Get('/findById', {
    type,
    id
  })
}
