<template>
  <div class="main-title">
    <img :src="src" :alt="alt" :title="alt">
  </div>
</template>
<script>
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    }
  }
}
</script>
<style lang="less" scoped>
  .main-title {
    padding: 40px 0;
    text-align: center;
  }
</style>
