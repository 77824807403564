<template>
  <div class="header">
    <div class="header-panel">
        <div class="logo">
          <a href="#">
            <img class="logoimg" alt="宏远控股" src="http://www.lndfwljt.com/image/2021/07/09/logo.png"/>
          </a>
        </div>
        <div class="navAll">
          <Menus />
        </div>
    </div>
</div>
</template>
<script>
import Menus from '../../public/header/menu'

export default {
  components: {
    Menus
  }
}
</script>
<style lang="less" scoped>
.header{width:100%;position:absolute;top:0;z-index:1000; min-width: 12rem;}
.header-panel{
  max-width:15.2rem;
  min-width:12rem;
  margin:auto;
  padding:0.2rem 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.logoimg{
  width: 140px;
  margin-left: 11%;
}
.header .logo{width:120px;height:auto;float: left;}

.navAll{font-size:0.14rem;color: #fff;line-height:0.4rem;float:right;}
.navAll > ul > li{height:0.4rem;padding:0 12px;float:left;line-height:0.4rem;display: inline-block;vertical-align: middle;}
.navAll ul>.current>a:hover{border-bottom:2px solid #6cbbe9;}
.navAll ul li img{display:block;line-height:0.4rem;vertical-align: middle;position: relative;bottom: -34%;}
.navAll a{ color: #fff;padding:0 0.08rem;display: block; }
</style>
