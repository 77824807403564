<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  watch: {
    $route () {
      window.scrollTo(0, 0)
    }
  }
}
</script>>

<style lang="less">
  @import "assets/base";
#app {
  font-family: "微软雅黑", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media screen and (max-width: 1199px) {
    #app {
      width: 1200px;
    }
}
@media screen and (min-width: 1200px) {
    #app {
      width: 100%
    }
}
</style>
