<template>
  <div>
  <ul class="menu">
    <DropDownMenu
      v-for="(item, index) in MENUS" :key="index"
      :item="item"
    />
  </ul>
  </div>
</template>
<script>
import { MENUS } from '../menus/menu.json'
import DropDownMenu from '../menus/dropdownmenu'
export default {
  data () {
    return {
      MENUS,
      toggle: false,
      find: '',
      notEntered: false,
      notFound: false,
      callFailed: false

    }
  },
  methods: {
  },
  components: {
    DropDownMenu
  }
}
</script>
<style lang="less" scoped>
.menu{font-size:14px;color: #fff;line-height:40px;float:right;}
.menu > li{height:40px;padding:0 12px;float:left;line-height:40px;display: inline-block;vertical-align: middle;}
.menu >.current>a:hover{border-bottom:2px solid #6cbbe9;}
.menu li img{display:block;line-height:40px;vertical-align: middle;position: relative;bottom: -34%;}
.menu a{ color: #fff;padding:0 8px;display: block; }

.toggle-menu {
  position: relative;
  &-icon {
    background: url('http://www.lndfwljt.com/image/2021/07/09/icon-search.png') no-repeat;
    background-position: center center;
    display: block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-top: 10px;
  }
  &-search {
    // position: absolute;
    left: 100%;
    top: 0px;
    width: 200px;
  }
}

.bounce-enter-active {
  animation: bounce-in .5s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}

</style>
