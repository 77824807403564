<template>
  <div class="swiper-slide">
    <dl>
      <dt><a  target="_blank" @click="goNew(item)"><img :src="item.coverMap"/></a></dt>
      <dd>
        <p class="tit">{{ item.title }}</p>
        <p class="txt">{{ item.createTime }}</p>
        <p class="txt">{{ item.newAbstract }}</p>
      </dd>
    </dl>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    goNew (item) {
      this.$router.push({
        name: 'newsDetail',
        params: {
          type: item.type,
          pageId: item.id
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .swiper-slide {
    dl {
      width: 100%;
      // 给页面加了个
      height: 400px;
      padding: 10px 60px;
      padding-left: 0px;
      img {
        width: 100%;
      }
    }
    dd {
      padding: 10px 0 30px;
      position: relative;
      .tit {
        font-size: 15px;
        color: #333;
        line-height: 0.5;
        font-weight: bold;
      }
      .txt {
        font-size: 12px;
        color: #808080;
        line-height: 1.6;
      }
    }
  }
</style>
