<template>
<div>
  <!-- begin footer -->
  <div class="footer clearfix">
      <div class="footer-panel clearfix">
          <div><img  class="logoimgtwo" src="http://www.lndfwljt.com/image/2021/07/09/logo2.png"/></div>
          <div class="f_link" v-for="(item, index) in showMenus" :key="index">
            <p class="ft-title">{{ item.name }}</p>
            <ul>
              <li v-for="(menuItem, index) in (item.children || [])" :key="index">
                <a href="" @click.prevent="jump(menuItem)">{{ menuItem.name }}</a>
              </li>
            </ul>
          </div>
          <div class="code2"><img src="http://www.lndfwljt.com/image/2021/07/09/code2.png"/></div>
          <div class="code3" style="right: 30px;"><img src="http://www.lndfwljt.com/image/2021/07/09/code3.jpg"/></div>
      </div>
  </div>
  <div class="footer-bttm">
    <div class="content">
      <a href="https://beian.miit.gov.cn/#/Integrated/index"><span class="right">辽ICP备2021007996号-1</span></a>
    </div>
  </div>
</div>
</template>
<script>
import { MENUS } from './menus/menu.json'
import { isUndefined } from 'lodash-es'
import { prevItem } from './menus/state'
export default {
  data () {
    return {
      MENUS,
      prevItem
    }
  },
  computed: {
    showMenus () {
      return MENUS.filter(item => {
        if (isUndefined(item.noShowFooter)) return true
        return false
      })
    }
  },
  methods: {
    jump (item) {
      console.log(item)
      if (JSON.stringify(item) === JSON.stringify(this.prevItem)) {
        return
      }
      const { router, params } = item
      if (router) {
        if (params) {
          this.$router.push({ name: router, params: { ...params } })
        } else {
          this.$router.push({ name: router })
        }
      }
      this.prevItem = item
    }
  }
}
</script>
<style lang="less" scoped>
  .footer {
    width: 100%;
    min-width: 1200px;
    color: #fff;
    background-color: #222;
    &-panel {
      max-width: 1550px;
      min-width: 1200px;
      padding: 60px 0 50px;
      margin: auto;
      line-height: 40px;
      box-sizing: border-box;
      display: flex;
      .logo {
        width: 217px;
        height: auto;
      }
      >.f_link {
        width: 12%;
        padding-left: 100px;
      }
      .ft-title {
        text-align: left;
        padding-bottom: 10px;
        margin-bottom: 10px;
        position: relative;
        line-height: 1.2;
        font-size: 18px;
        &:after {
          content: '';
          width: 25px;
          height: 2px;
          background-color: #336fd0;
          position: absolute;
          bottom: 0;
          left: 5px;
          display: block;
        }
      }
      .f_link {
        li {
          font-size: 14px;
          color: #a3a3a3;
          line-height: 2.5;
        }
        a {
          color: #a3a3a3;
          text-decoration: none;
          &:hover {
            color: darken(#a3a3a3, 10%);
          }
        }
      }
    }
  }
  .footer-bttm {
    width: 100%;
    min-width: 1200px;
    border-top: 1px solid #343434;
    background-color: #222;
    .content {
      max-width: 1540px;
      min-width: 1200px;
      margin: auto;
      padding: 0 50px;
      color: #717171;
      line-height: 62px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
    }
  }
  .code2{
    padding-left: 100px;
  }
  .code3{
    padding-left: 50px;
  }
  .logoimgtwo{
    width: 217px;
  }
</style>
