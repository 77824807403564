import axios from 'axios'

const prefix = ''
// axios.defaults.baseURL = 'http://hycw.yezisw.com/'

// get请求
export const Get = (url, params = {}) => {
  return axios.get(`${prefix}${url}`, {
    params
  }).then(({ data }) => data)
}

// post请求
export const Post = (url, data = {}, config = {}) => {
  return axios.post(`${prefix}${url}`, data, {
    ...config
  }).then(({ data }) => data)
}
