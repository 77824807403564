export const MENUS = [{
  noShowFooter: false,
  name: '首页',
  router: 'main'
}, {
  name: '关于我们',
  children: [{
    name: '公司介绍',
    router: 'About',
    params: { type: '1' }
  }, {
    name: '发展历程',
    router: 'About',
    params: { type: '2' }
  }, {
    name: '公司文化',
    router: 'About',
    params: { type: '3' }
  }, {
    name: '四大体系',
    router: 'About',
    params: { type: '5' }
  }, {
    name: '战略方针',
    router: 'About',
    params: { type: '6' }
  }]
}, {
  name: '集团业务',
  children: [{
    name: '批发物流',
    router: 'WholesaleLogistics'
  }, {
    name: '零售连锁',
    router: 'RetailChain'
  }, {
    name: '生物科技',
    router: 'BiologicalTechnology'
  }, {
    name: '电子商务',
    router: 'ECommerce'
  }, {
    name: '生产研发',
    router: 'PRD'
  }, {
    name: '健康孵化',
    router: 'HealthHatch'
  }]
}, {
  name: '新闻资讯',
  children: [{
    name: '公司动态',
    router: 'News',
    params: { type: '1' }
  }, {
    name: '媒体报道',
    router: 'News',
    params: { type: '2' }
  }]
}, {
  name: '联系我们',
  children: [{
    name: '联系我们',
    router: 'Contact'
  }]
}]
