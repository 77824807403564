import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'ant-design-vue/dist/antd.css'
import Antd from 'ant-design-vue'
import './styles/main.less'
import animate from 'animate.css'
import ScrollView from 'vue-scrollview'
import axios from 'axios'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
// 访问前缀
// axios.defaults.baseURL = 'http://hycw.yezisw.com/hy'
// axios.defaults.baseURL = 'http://hyweb.yezisw.com/hy'
// if (process.env.NODE_ENV === 'development') {
// axios.defaults.baseURL = 'http://hyweb.yezisw.com/api/reception'
// } else {
//  axios.defaults.baseURL = 'http://www.pekhongyuan.com/hy/reception'
// }
axios.defaults.baseURL = 'https://www.lndfwljt.com/api'
// axios.defaults.baseURL = 'http://www.pekhongyuan.com/hy'
// axios.defaults.baseURL = 'http://abc.pekhongyuan.com/hy'
// axios.defaults.baseURL = 'http://119.29.52.164/hy'
// axios.defaults.baseURL = 'http://localhost:8000/api/reception'
Vue.use(Antd)
Vue.use(animate)
Vue.use(ScrollView)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

document.oncontextmenu = function (e) {
  return false
}
