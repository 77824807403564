import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/index.vue'

Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (location) {
//   return (originalPush.call(this, location)).catch((err) => console.log(err))
// }

const routes = [
  {
    path: '/',
    name: 'main',
    component: Home
  },
  {
    path: '/social/:type',
    name: 'Social',
    component: () => import(/* webpackChunkName: "about" */ '../views/social-responsibility/index.vue')
  },
  {
    path: '/about/:type',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue')
  },
  {
    path: '/news/:type',
    name: 'News',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/index.vue')
  },
  {
    path: '/news/:type/:pageId',
    name: 'newsDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/news/detail.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/contact/index.vue')
  },
  {
    path: '/eCommerce',
    name: 'ECommerce',
    component: () => import(/* webpackChunkName: "about" */ '../views/ECommerce/index.vue')
  },
  {
    path: '/concept',
    name: 'Concept',
    component: () => import(/* webpackChunkName: "about" */ '../views/concept/index.vue')
  },
  {
    path: '/biologicalTechnology',
    name: 'BiologicalTechnology',
    component: () => import(/* webpackChunkName: "about" */ '../views/BiologicalTechnology/index.vue')
  },
  {
    path: '/retailChain',
    name: 'RetailChain',
    component: () => import(/* webpackChunkName: "about" */ '../views/RetailChain/index.vue')
  },
  {
    path: '/wholesaleLogistics',
    name: 'WholesaleLogistics',
    component: () => import(/* webpackChunkName: "about" */ '../views/WholesaleLogistics/index.vue')
  },
  {
    path: '/prd',
    name: 'PRD',
    component: () => import(/* webpackChunkName: "about" */ '../views/PRD/index.vue')
  },
  {
    path: '/healthHatch',
    name: 'HealthHatch',
    component: () => import(/* webpackChunkName: "about" */ '../views/HealthHatch/index.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
