<template>
  <div class="main">
    <!-- 标题 -->
    <MTitle :src="tit1" alt="集团业务" />
    <Business />
    <!-- title -->
    <MTitle :src="tit2" alt="全球布局" />
    <Global />
    <!-- title -->
    <MTitle :src="tit3" alt="新闻资讯" />
    <!-- title end -->
    <div class="news w1200">
      <!-- <dl class="news-main">
        <dt><a href=""><img src="http://www.lndfwljt.com/image/2021/07/09/img21.jpg"/></a></dt>
        <dd>
            <p class="tit">北京2022年冬奥会主物流中心建设进入收尾阶段</p>
            <p class="txt">空港宏远作为北京2022年冬奥会和冬残奥会官方仓储设施独家供应商，自2019年12月开始建设主物流中心，项目整体历时400余天，目前，主物流中心库房的绝大部分建设项目已经完成。</p>
            <p class="bttm">2021/01/05<a href="">>更多</a></p>
        </dd>
      </dl> -->
      <dl class="news-main">
        <dt><a target="_blank" @click="goNew(newsInfo)"><img :src="newsInfo.coverMap"/></a></dt>
        <dd>
            <p class="tit">{{ newsInfo.title }}</p>
            <p class="txt">{{ newsInfo.newAbstract  || '暂无摘要'}}</p>
            <p class="bttm">{{ newsInfo.createTime }}<a target="_blank" @click="goNew(newsInfo)">>更多</a></p>
        </dd>
      </dl>
    </div>
    <div class="news-main-swiper">
      <div class="w1200" style="max-width: 1521px">
       <SwiperNews :newList="newList" />
      </div>
    </div>
  </div>
</template>
<script>
import SwiperNews from './new-swiper'
import MTitle from './title'
import Business from './business'
import Global from './global'
import { fetchOtherNewLists } from '@/api/news'

export default {
  components: {
    SwiperNews,
    MTitle,
    Business,
    Global
  },
  data () {
    return {
      tit1: 'http://www.lndfwljt.com/image/2021/07/09/title1.png',
      tit2: 'http://www.lndfwljt.com/image/2021/07/09/title2.png',
      tit3: 'http://www.lndfwljt.com/image/2021/07/09/title3.png',
      newList: [],
      newsOutline: {},
      newsInfo: {}
    }
  },
  async mounted () {
    const topData = await fetchOtherNewLists({ pageNum: 1, seatTop: 1, seat: 1 })
    if (topData.code === 200) {
      this.newList = topData.data.records
      this.newsInfo = this.newList[0]
    }
    const listData = await fetchOtherNewLists({ pageNum: 1, seatTop: 0, seat: 1 })
    if (listData.code === 200) {
      this.newList = listData.data.records
    }
  },
  methods: {
    goNew (item) {
      this.$router.push({
        name: 'newsDetail',
        params: {
          type: item.type,
          pageId: item.id
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .main {
    min-width: 1200px;
  }
  .title {
    padding: 40px 0;
    text-align: center;
  }
  .news-main {
    padding: 10px;
    margin: 0 auto 30px;
    box-sizing: border-box;
    display: flex;
    dt {
      width: 50%;
      padding-right: 20px;
      float: left;
      box-sizing: border-box;
      img {
        width: 100%;
        height: auto;
        border-radius: 2px;
        box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
      }
    }
    dd {
      width: 50%;
      padding: 10px 10px 70px;
      box-sizing: border-box;
      float: left;
      position: relative;
      &:hover {
        cursor: pointer;
        .tit {
          color: #1d3e7e;
        }
        .txt {
          color: #6cbbe9;
        }
      }
      .tit {
        font-size: 18px;
        line-height: 2;
        color: #333;
        font-weight: bold;
      }
      .txt {
        font-size: 16px;
        color: #808080;
        line-height: 1.6;
      }
      .bttm {
        line-height: 50px;
        font-size: 16px;
        color: #666;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
      a {
        font-size: 16px;
        color: #336fd0;
        float: right;
      }
    }
    &-swiper {
      background: url('http://www.lndfwljt.com/image/2021/07/09/bg2.jpg') no-repeat center center;
      background-size: auto;
      position: relative;
      background-color: rgba(243, 247, 250);
    }
  }
</style>
