<template>
  <div class="group-business flexAround">
    <div class="gb-box" v-on:click.prevent="go('/wholesaleLogistics')">
      <img class="bgImg" src="http://www.lndfwljt.com/image/2021/07/09/img12.jpg" title="国际贸易"/>
      <div class="bg-box-current" />
      <img :class="`txtImg item2`" src="http://www.lndfwljt.com/image/2021/07/09/img12.png"/>
      <div class="detail">
        <div>辽宁九丰药业有限公司</div>
        <div>医药批发<div style="width: 5%;height: 5%"></div>物流配送<div style="width: 5%;height: 5%"></div>三方服务</div>
      </div>
    </div>
    <div class="gb-box" v-on:click.prevent="go('/retailChain')">
      <img class="bgImg" src="http://www.lndfwljt.com/image/2021/07/09/img15.jpg" title="临空服务"/>
      <div class="bg-box-current" />
      <img :class="`txtImg item4`" src="http://www.lndfwljt.com/image/2021/07/09/img15.png"/>
      <div class="detail">
        <div>辽宁五洲通大药房连锁有限公司</div>
        <div>一家迅速崛起的大型医药零售连锁企业</div>
      </div>
    </div>
    <div class="gb-box" v-on:click.prevent="go('/biologicalTechnology')">
      <img class="bgImg" src="http://www.lndfwljt.com/image/2021/07/09/img14.jpg" title="临空服务"/>
      <div class="bg-box-current" />
      <img :class="`txtImg item4`" src="http://www.lndfwljt.com/image/2021/07/09/img14.png"/>
      <div class="detail">
        <div>辽宁九丰生物科技有限公司</div>
        <div>专业从事医疗器械研发及销售</div>
      </div>
    </div>
    <div class="gb-box" v-on:click.prevent="go('/eCommerce')">
      <img class="bgImg" src="http://www.lndfwljt.com/image/2021/07/09/img11.jpg" title="物流服务"/>
      <div class="bg-box-current" />
      <img :class="`txtImg item1`" src="http://www.lndfwljt.com/image/2021/07/09/img11.png"/>
      <div class="detail">
        <div>辽宁大方未来科技有限公司</div>
        <div>多元化的医药互联网商城</div>
      </div>
    </div>
    <div class="gb-box" v-on:click.prevent="go('/prd')">
      <img class="bgImg" src="http://www.lndfwljt.com/image/2021/07/09/img16.jpg" title="临空服务"/>
      <div class="bg-box-current" />
      <img :class="`txtImg item4`" src="http://www.lndfwljt.com/image/2021/07/09/img16.png"/>
      <div class="detail">
        <div>辽宁五洲易联生物科技有限公司</div>
        <div>医疗机械生产服务平台</div>
      </div>
    </div>
    <div class="gb-box" v-on:click.prevent="go('/healthHatch')">
      <img class="bgImg" src="http://www.lndfwljt.com/image/2021/07/09/img13.jpg" title="供应链服务"/>
      <div class="bg-box-current" />
      <img :class="`txtImg item3`" src="http://www.lndfwljt.com/image/2021/07/09/img13.png"/>
      <div class="detail">
        <div>大方众创空间</div>
        <div>医药健康产业专业化孵化平台</div>
      </div>
    </div>
  </div>
</template>
<script>
import { data as Items } from './business.json'

export default {

  data () {
    return {
      Items
    }
  },
  methods: {
    go (href) {
      this.$router.push({ path: href })
    }
  }
}
</script>
<style lang="less" scoped>
  .group-business {
    padding: 20px 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 1200px;
    margin: 0 auto;
  }
</style>
<style lang="less" scoped>
  .gb-box {
    position: relative;
    width: 25%;
    box-sizing: border-box;
    margin: 5px;
    .bg-box-current {
      position: absolute;
      background: #529fcc;
      transition: all ease-in-out 300ms;
      width: 0;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
    }
    &:hover {
      cursor: pointer;
      .bg-box-current {
        width: 100%;
      }
      .detail {
        display: flex;
      }
    }
    .bgImg {
      width: 100%;
    }
    .txtImg {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 2;
      width: 30px;
    }
    .detail {
      position: absolute;
      display: none;
      left: 70px;
      top: 72px;
      z-index: 3;
      color: #fff;
      font-size: 18px;
      div {
        width: 18px;
        margin-left: 20px;
      }
    }
  }
</style>
