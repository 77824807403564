<template>
  <div>
    <MainHeader />
    <MainBanner />
    <MainContent />
    <MainFooter />
  </div>
</template>
<script>
import MainHeader from './components/main-header'
import MainBanner from './components/main-banner'
import MainContent from './components/main-content'
import MainFooter from '../public/footer'

import { getIndexInfo } from '@/api/home.js'

export default {
  components: {
    MainHeader,
    MainBanner,
    MainContent,
    MainFooter
  },
  methods: {
    getIndexInfo () {
      getIndexInfo().then().catch()
    }
  }
}
</script>
