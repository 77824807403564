<template>
<div class="global-layout" @mouseenter="handleEnter" @mouseleave="handleLeave">
  <img class="boderimg" src="http://www.lndfwljt.com/image/2021/07/09/bg3.jpg"/>
</div>
</template>
<script>
export default {
  data () {
    return {
      current: false
    }
  },
  methods: {
    handleEnter () {
      this.current = true
    },
    handleLeave () {
      this.current = false
    }
  }
}
</script>
<style lang="less" scoped>
  .global-layout {
    width: 100%;
    position: relative;
    img {
      width: 100%;
      height: auto;
      min-height: 300px;
    }
    &-bg {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      background: rgba(0, 0, 0, 0.2);
    }
    .text {
      padding: 40px 0;
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      font-size: 24px;
      color: #fff;
      line-height: 2;
      text-align: center;

    }
  }
  .boderimg {
    width: 752.88px;
  }
</style>
